import { useAuth0 } from '@auth0/auth0-react';
import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import RedirectScreen from '~/components/RedirectScreen';

const Custom404Page = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const handleRedirect = () => {
    if (!isAuthenticated && !isLoading) loginWithRedirect();
  };
  useEffect(() => {
    const timeout = setTimeout(handleRedirect, 300);
    return () => clearTimeout(timeout);
  }, [isLoading, isAuthenticated]);

  return isAuthenticated ? (
    <VStack h="100vh" alignItems="center" justifyContent="center">
      <HStack spacing="6">
        <Heading>404</Heading>
        <Box h="50px" borderRightWidth="1.5px" borderColor="black" />
        <Text size="2xl">This page could not be found.</Text>
      </HStack>
    </VStack>
  ) : (
    <RedirectScreen />
  );
};

export default Custom404Page;
