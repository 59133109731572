import { CircularProgress, Heading, HStack } from '@chakra-ui/react';

const RedirectScreen = () => (
  <HStack alignItems="center" justifyContent="center" w="100vw" h="100vh">
    <CircularProgress
      thickness="5px"
      size="30px"
      isIndeterminate
      color="brand.perwinkle.500"
    />
    <Heading>REDIRECTING...</Heading>
  </HStack>
);

export default RedirectScreen;
